
import React from "react";
import "./ReminderRow.css"
import { sendReminderEmail } from "../../../../TemplativeClient";
import { getCookie,setCookie } from "../../../../CookieManagement";

export default class ReminderRow extends React.Component {
    state = {
        email: "oliverbarnum32@gmail.com",
        hasSentReminderEmail: false,
        isReminderOpen: false,
        isReminderRowHidden: true
    }
    updateEmail = async (event) => {
        this.setState({email: event.target.value})
    } 
    sendReminderEmail = async () => {
        console.log(this.state.email)
        var sent = await sendReminderEmail(this.state.email)
        if (!sent) {
            return
        }
        setCookie("REMINDER_EMAIL", this.state.email, 999)
        this.setState({ hasSentReminderEmail: true, email: this.state.email })
    }
    componentDidMount = async () => {
        var reminderEmail = getCookie("REMINDER_EMAIL")
        var hasSentReminderEmail = reminderEmail !== null
        var email = reminderEmail != null ? reminderEmail : ""
        console.log(reminderEmail)
        this.setState({ hasSentReminderEmail: hasSentReminderEmail, email: email, isReminderRowHidden: reminderEmail !== null  })
    }
        
    render() {   
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        var isInvalidEmail = !emailRegex.test(this.state.email);
        var content = undefined
        if (!this.state.isReminderRowHidden) {
            content = this.state.hasSentReminderEmail ? 
                <p>Your reminder email was to {this.state.email}!</p>
                :    
                <>
                    { this.state.isReminderOpen ? 
                        <p>We'll send a reminder to your inbox. You will receive exactly one reminder email. Never again.</p>
                        :
                        <p className="clickable-suggestion" onClick={()=>{this.setState({isReminderOpen:true})}}>Want an email reminder?</p> 
                    }
                    {this.state.isReminderOpen &&
                        <div className="input-group input-group-sm" data-bs-theme="dark">
                            <span className="input-group-text">Email</span>
                            <input type="email" className="form-control" placeholder=""
                                value={this.state.email} 
                                onChange={this.updateEmail} 
                                />
                            <button type="button" className="btn btn-primary" onClick={this.sendReminderEmail} disabled={isInvalidEmail}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mailbox-flag" viewBox="0 0 16 16">
                                    <path d="M10.5 8.5V3.707l.854-.853A.5.5 0 0 0 11.5 2.5v-2A.5.5 0 0 0 11 0H9.5a.5.5 0 0 0-.5.5v8zM5 7c0 .334-.164.264-.415.157C4.42 7.087 4.218 7 4 7s-.42.086-.585.157C3.164 7.264 3 7.334 3 7a1 1 0 0 1 2 0"/>
                                    <path d="M4 3h4v1H6.646A4 4 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3V3a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4m0 1a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3"/>
                                </svg>
                            </button>
                        </div>
                    }
                </>
            
        }
        return (
            <div className="reminder-row">
                {content}
            </div>
        );
    }
}
