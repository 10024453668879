import React from "react";
import "../TopBar.css"

import Nav from 'react-bootstrap/Nav';

export default class NavbarLink extends React.Component {    
    goToLink = () => {
        if (this.props.isGlobalEndpoint) {
            window.location.replace(this.props.endpoint);
            return
        }
        window.location.href = `${window.location.origin}/${this.props.endpoint}`        
    }
    render() {
        return (
            <Nav.Link className="topbar-link" onClick={this.goToLink}>{this.props.endpointName}</Nav.Link>
        );
    }
}