
import React from "react";

const personas = [
    {
        emoji: "🧑‍🎨",
        name: "Artist",
        reason: "Focus on creating good art once, without wasting your time."
    },
    {
        emoji: "👷",
        name: "Game Designer",
        reason: "Unblock your design work by automating art updates for new experiments."
    },
    {
        emoji: "🤵",
        name: "Publisher",
        reason: "Coordinate the needs of design and art with easy collaboration through Git and Drive."
    },
    
]

export default class IsItRight extends React.Component {
    render() {
        return (
            <div className="row justify-content-center annoyance-row">
                <div className="col">
                    <h1 className="honest">Is Templative right for me?</h1>
                    { personas.map(persona =>    
                        <div className="example-person">
                            <h2>{persona.emoji}</h2>
                            <h2>{persona.name}</h2>
                            <p>{persona.reason}</p>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
