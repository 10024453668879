
import React from "react";
import TemplativePurchaseSuggestion from "../../TemplativePurchaseSuggestion";
import tabletopPlayground from "./tabletopPlayground.png"
import tabletopPlaygroundPackages from "./tabletopPlaygroundPackages.png"
import yourPackage from "./yourPackage.png"
import inplay from "./inplay.png"
import uploadPackage from "./uploadPackage.png"
import uploadPackageControls from "./uploadPackageControls.png"

export default class TabletopPlayground extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Exporting to Tabletop Playground</h1>
            <p>Export to Tabletop Playground to playtest your game with your friends virtually.</p>
            <TemplativePurchaseSuggestion isLoggedIn={this.props.isLoggedIn} doesOwnTemplative={this.props.doesOwnTemplative}/>
            <p>Why Tabletop Playground? Tabletop Simulator is not allowed in the great playtesting community <a href="https://discord.gg/RasquGsN">Break my Game Discord</a>, while Tabletop Playground is.</p>
            <p>Tabletop Playground handles custom content in what are called packages.</p>
            <p>To create a starter package with all your content in Tabletop Playground:</p>
            
            
                <p>Open the Tabletop Playground Screen.</p>
                <p>Select the folder where Templative should put your packages (usually stored in `...\Steam\steamapps\common\TabletopPlayground\TabletopPlayground\PersistentDownloadDir` and `~/Library/Application Support/Epic/TabletopPlayground`) but it may depend on your OS and how you installed the app.</p>
                <p>Select an outputed folder.</p>
                <p>Press Create Playground Package. The thumbnail section will be blank until you load it into Tabletop Playground. Note that the packages folder will show <em>any</em> packages you create for Tabletop Playground, not just for this game.</p>
                <img src={tabletopPlayground} alt="Tabletop Playground screen"/>
                <p>Launch Tabletop Playground.</p>
                <p>Click Editor.</p>
                <img src={tabletopPlaygroundPackages} alt="Tabletop Playground packages"/>
                <p>Select your Package. Notice how the thumbnails for all your components are generated for you.</p>
                <img src={yourPackage} alt="Your package within Tabletop Playground"/>
                <p>Click `Preview Package or Create Start States`.</p>
                <p>Click `Start with Empty Table`, select a table, background, and then press `Start`.</p>
                <p>Once at your table, right click, open the Object Library.</p>
                <p>For each of your components, click and place it within your table.</p>
                <img src={inplay} alt="A game in play."/>
            
            <h2>Sharing your Tabletop Package on mod.io</h2>
            <p>Share your game by uploading your package to mod.io. Open your package and click `Upload Package`.</p>
            <img src={uploadPackage} alt="The upload button."/>
            <p>Give it a good name, give it a thumbnail, and description and press Upload.</p>
            <img src={uploadPackageControls} alt="The upload controls."/>
            <p>Go to the mod.io link, log into your steam account, and make your package visibile if you haven't already.</p>
            
            <p>Consider reading <a href="https://tabletop-playground.com/knowledge-base/">Tabletop Playground's documentation</a>.</p>
        </React.Fragment>
    }
}