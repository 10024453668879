
import React from "react";
import "./CompanyInfo.css"

export default class TermsOfUseLanding extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="content col-12 col-sm-8">
                <h1>Terms and Conditions</h1>
                <p><strong>Effective Date:</strong> 7/22/2024</p>
                <p>Welcome to Templative, a product of Go Next Games LLC. By using our services, you agree to the following terms and conditions. Please read them carefully.</p>

                <h2>1. Acceptance of Terms</h2>
                <p>By accessing and using Templative, you accept and agree to be bound by these terms and conditions. If you do not agree to these terms, please do not use our services.</p>

                <h2>2. User Obligations and Responsibilities</h2>
                <p>As a user of Templative, you are expected to:</p>
                <ul>
                    <li>Generate board game art.</li>
                    <li>Create packages for use in the video game Tabletop Playground.</li>
                    <li>Upload your board games to the website <a href="https://www.thegamecrafter.com/" target="_blank">https://www.thegamecrafter.com/</a>.</li>
                    <li>Create PDFs.</li>
                </ul>

                <h2>3. Ownership and Intellectual Property</h2>
                <p>Any and all art or derivatives created with Templative are your property. Go Next Games LLC does not claim any ownership rights over the content created by you using Templative.</p>

                <h2>4. Use of Third-Party APIs</h2>
                <p>Any use of The Game Crafter APIs is solely your responsibility, and Go Next Games LLC disclaims any and all liability arising from such use. The relationship between you and The Game Crafter, as well as the owners of Tabletop Playground, is independent of Go Next Games LLC. Go Next Games LLC shall not be held liable for any misuse or unauthorized use of these third-party APIs. It is your duty to comply with all terms and conditions imposed by The Game Crafter and other third-party service providers.</p>

                <h2>5. Termination of Access</h2>
                <p>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions. Upon termination, Your right to use the Service will cease immediately.</p>
                Termination

                <h2>6. Limitation of Liability</h2>
                <p>Go Next Games LLC will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of Templative.</p>

                <h2>7. Governing Law</h2>
                <p>These terms and conditions are governed by and construed in accordance with the laws of the United States of America.</p>

                <h2>8. Dispute Resolution</h2>
                <p>If you have any disputes with us, please contact us directly at <a href="mailto:oliver@gonextgames.com">oliver@gonextgames.com</a> to resolve the issue.</p>

                <h2>9. Modifications to Terms</h2>
                <p>We may update these terms and conditions from time to time. We will notify you of any changes by sending an email to the address associated with your account.</p>

                <h2>10. Contact Information</h2>
                <p>If you have any questions or concerns about these terms and conditions, please contact us at:</p>
                <p><strong>Go Next Games LLC</strong><br/>
                Email: <a href="mailto:oliver@gonextgames.com">oliver@gonextgames.com</a></p>
                </div>
            </div>
        );
    }
}