
import React from "react";
import RegisterForm from "./LoginForm/RegisterForm";
// import "./CompanyInfo.css"

export default class RegisterLanding extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="content col-12 col-sm-8">
                    <RegisterForm/>
                </div>
            </div>
        );
    }
}