
import React from "react";
import HeadsUp from "../../../HeadsUp";
import backgroundColorUpdate from "./backgroundColorUpdate.png"
import artFiles from "./artFiles.png"
import findingXmlEditor from "./findingXmlEditor.png"
import renamingId from "./renamingId.png"
import referenceToGradient from "./referenceToGradient.png"
import stopColorStyle from "./stopColorStyle.png"
import operations from "./operations.png"
import assigningStopColor from "./assigningStopColor.png"
import sourcingData from "./sourcingData.png"
import atTheTop from "./atTheTop.png"
import positioning from "./positioning.png"

export default class Artdata extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Artdata</h1>
            <p>Use artdata files to tell Templative how to modify <a href={`${window.location.origin}/docs/1-making-your-game/editing-your-components/art`}>Art</a> using your <a href={`${window.location.origin}/docs/1-making-your-game/editing-your-components/gamedata`}>Gamedata</a>.</p>
            <p>Each component has one to two pieces of artdata. Usually there is a front artadata, and a backartdata. Artdata files themselves can be reused across components, but it is not atypical for every single component to have its own artdata.</p>

            <h2>Operations</h2>
            <p>Artdata commands are seperated into overlays, style updates, and text replacements.</p>
            <img src={operations} alt="Artdata operation examples"/>
            <p>These operations are done in order, starting with loading the template art file specified at the top. By default, Templative looks for the starter template files in the `/art/templates` folder.</p>
            <img src={atTheTop} alt="An example artdata file"/>

            <p>As an example, if we wanted to change the background color to the `teamColor` value of the deck, we could create a style update that finds the element in the art file with the id "background" and set it's `fill` property to the value stored in the `teamColor` field of the deck's component gamedata.</p>
            <img src={backgroundColorUpdate} alt="Image of replacing the color"/>

            <h3>Overlays</h3>
            <p>Use overlays to layer svg files ontop of one another. By default, Templative looks for overlays in the `art/graphicalInserts` directory.</p>
            <img src={artFiles} alt="Graphic inserts directory"/>
            <p>In practice, this means copying the underlying template art file into a new document saved into the graphicInserts directory.</p>
            <p>By default, the top left corner of overlay is placed at the top left corner of the template document. But we can also manually specify the position of the top left corner of our overlay. To make sure you like its position, copy the x,y positions of the overlay in the main template file and pass those values to Templative.</p>
            <img src={positioning} alt="Pulling the xy positions from Inkscape."/>

            <h3>Style Updates</h3>
            <p>Vector art is made up of shapes that have styling applied to them, such as what color it is `fill`'d with. It works very similarly to the CSS styling of html documents.</p>

            <p>We use style updates predominantly to change the fill, stroke color, font-size, and size of object in our files. To create a style update, we pick the element we want to change by it's ID {"("}see below{")"}, pick which style property we want to change, and then select which value goes in that style property.</p>

            <h4>Assigning ids in Inkscape</h4>
            <p>SVG files are simply <a href="https://aws.amazon.com/what-is/xml/#:~:text=An%20Extensible%20Markup%20Language%20(XML,similar%20to%20other%20text%20files.">xml files</a> under the hood. xml is a common "human readable" data format, like JSON. Inkscape provides a useful in app xml editor for our svg files.</p>

            <img src={findingXmlEditor} alt="Dropdown to access xml editor"/>

            <p>Select an object in your art file, note that the corresponding xml element is selected in the xml editor. From here, we can select the id attribute of the element, rename it, and press enter.</p>
            
            <img src={renamingId} alt="xml id attribute"/>

            
            <h3>Text Replacements</h3>
            <p>Use text replacements to pipe text data into your art files. Instead of manually writing your content into your art files, instead write {"{name}"} in your art file.</p>
            
            <h3>Text Replacements within Text Replacements</h3>
            <p>A text replacement can contain a curly brace wrapped text within it. For instance, the rules value of a piece might be "Upgrade the power by {"{powerLevel}"}" which is followed by a text replacement for that powerlevel.</p>

            <h2>Piping Content into your Artdata</h2>
            <p>In Templative, a singular piece, such as a single poker card, has unique data associated with it. That piece in turn has data associated with it at the <em>component</em> level. It also by extension has access to any data about the game and studio it is a part of.</p>
            <p>All of this information is easily accessible within Templative. If I want to set the value of an overlay, style update, or text replacement to a field in the gamedata of the piece, component, game, studio, give the field and the source it's from to Templative.</p>
            <img src={sourcingData} alt="Pulling the displayName of the game"/>
            <p>In this example we pull the name of the game from the `Game` source and the `displayName` field.</p>
            <p>See the <a href={`${window.location.origin}/docs/1-making-your-game/editing-your-components/gamedata`}>Gamedata</a> article for more information on how gamedata works.</p>
        
        </React.Fragment>
    }
}