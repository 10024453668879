
import React from "react";
import TemplativePurchaseSuggestion from "../../TemplativePurchaseSuggestion";
import HeadsUp from "../../HeadsUp";
import theGameCrafterScreen from "./theGameCrafterScreen.png"
import theGameCrafterYourAccount from "./theGameCrafterYourAccount.png"
import uploadingInProgress from "./uploadingInProgress.png"
import gameCrafterStudioGamedata from "./gameCrafterStudioGamedata.png"
import creatingAnApiKey from "./creatingAnApiKey.png"
import publishing from "./publishing.png"
import uploadingLogging from "./uploadingLogging.png"
import uploadedComponents from "./uploadedComponents.png"
import yourAccount from "./yourAccount.png"
import gameData from "./gameData.png"
import uploadDone from "./uploadDone.png"
import theGameCrafterAdFiles from "./theGameCrafterAdFiles.png"

export default class UploadingtotheGameCrafter extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Uploading to the TheGameCrafter</h1>
            <p>Upload to <a href="https://www.thegamecrafter.com/make/getting-started">TheGameCrafter</a> to purchase and sell your game.</p>
            <TemplativePurchaseSuggestion isLoggedIn={this.props.isLoggedIn} doesOwnTemplative={this.props.doesOwnTemplative}/>
            <p>TheGameCrafter is a great resource for prototyping and reducing your risk when selling your games.</p>
            <p>To automagically upload to TheGameCrafter, go to TheGameCrafter screen.</p>
            <img src={theGameCrafterScreen} alt="TheGameCrafter screen."/>
            <p>We need an ApiKey and DesignerId to upload to TheGameCrafter automagically. Sign into <a href="https://www.thegamecrafter.com">TheGameCrafter.com</a>. Go to the <a href="https://www.thegamecrafter.com/account/overview">Your Account page</a> Under the Publishing section and click Designers.</p>
            <img src={publishing} alt="Publishing on TheGameCrafter."/>
            <p>If you do not have a designer, create one. From there, click into your designer. Note the url of the Designer edit page, it should be something like `https://www.thegamecrafter.com/make/designer/YOUR-DESIGNER-ID-HERE`. Copy the DesignerId of the url and paste it into the Studio Gamedata of your game.</p>
            <img src={gameCrafterStudioGamedata} alt="Adding the DesignerId to Studio Gamedata."/>
            <p>Render your game again so the DesignerId update shows up in modern renders.</p>
            <p>Back on TheGameCrafter.com, click ApiKeys under the Your Account section.</p>
            <img src={yourAccount} alt="Your Account options on TheGameCrafter."/>
            <p>You will probably be asked to verify your login by email. Verify it.</p>
            <p>Create an ApiKey by typing your name and Templative for Application Name, provide "https://www.templative.net" for the Application Url, and "Uploading to TheGameCrafter through Templative" as the reason.</p>
            <img src={creatingAnApiKey} alt="Creating your ApiKey."/>
            <p>Grab the Public Key of the ApiKey you generated and drop it in the ApiKey section of the Templative upload controls. Put in your username and password for TheGameCrafter.</p>
            <p>Toggle Publish? on to use the displayName of the game instead of the name when uploading. Toggle Proofed? on to mark all art as proofed already. Toggle Include Stock? to include stock components like coins and counters in this upload.</p>
            <p>Now, click `Upload to TheGameCrafter`.</p>
            <img src={uploadingInProgress} alt="The filled out upload controls."/>
            <HeadsUp message="Templative uses your username and password to make requests on your behalf. The password is not saved to my server, and you will have to input it every time."/>
            <p>Watch as the file uploads take place in sequence.</p>
            <img src={uploadingLogging} alt="Upload logs."/>
            <p>Click the final link at the top of the logs to open your completed game.</p>
            <img src={uploadedComponents} alt="Our Uploaded Components."/>
        <h3>A Request from the Developer of Templative</h3>
        <p>Please be considerate of TheGameCrafter when uploading your game. We do not want to overload their servers with how many art files we bombard them with, at the same time, I do love sending them business.</p>
            
        <h2>Uploading the Shop Graphics</h2>
        <p>If you want to include TheGameCrafter shop graphics as part of your upload, update the art files in the `./gamecrafter` folder of your project.</p>
        <img src={theGameCrafterAdFiles} alt="The art files for theGameCrafter store"/>
        <p>Do not change the names or sizes of these are files, they are very specific. Any updates to these files will show up in theGameCrafter screen.</p>
        <p>To change what the shop says, edit your gamedata file. The category, coolFactors (three comma seperated items), short description, long description, maxPlayers, minAge, minPlayers, playTime, rulesDescription, tagline, tags, and website url all feed directly into theGameCrafter's shop fields.</p>
        <img src={gameData} alt="TheGameCrafter fields in your Game gamedata."/>
        <p>Once done, your art files will be included with your upload to pretty up your page.</p>
        <img src={uploadDone} alt="The prettied up GameCrafter store."/>
        </React.Fragment>
    }
}