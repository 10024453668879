
import React from "react";
import HeadsUp from "../../../HeadsUp";
import backgroundColorUpdate from "./backgroundColorUpdate.png"
import artFiles from "./artFiles.png"
import findingXmlEditor from "./findingXmlEditor.png"
import renamingId from "./renamingId.png"
import referenceToGradient from "./referenceToGradient.png"
import stopColorStyle from "./stopColorStyle.png"
import operations from "./operations.png"
import assigningStopColor from "./assigningStopColor.png"
import sourcingData from "./sourcingData.png"
import atTheTop from "./atTheTop.png"
import positioning from "./positioning.png"

export default class ArtdataTricks extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Artdata Tricks</h1>
            <p>Inkscape is confusing at first, but once learned is very powerful.</p>
            
            <h2>Unique Backs</h2>
            <p>Rather than make a new component per unique back, Templative is capable of creating new components per unique back in a component. For instance, if a component with 12 cards in it has only three unique faces, but has four unique backColor's, Templative will split this component into four seperate components for you.</p>

            <h2>Modifying Gradients in Templative</h2>
            <p>Gradients are handled by seperate xml entities from objects they color. Usually, an object's fill is set to a hexcode like #ffffff. When an object's background is set to a gradient however, the fill is set to the id of the gradient. The seperate gradient object in the svg file has style properties for the beginning and end colors of the gradient.</p>

            <img src={referenceToGradient} alt="The seperate gradient object"/>

            <p>To modify a gradient from Templative therefore, rather than grabbing the id of the object whose fill is a gradient, we grab the id of the gradient object itself, and manually set the beginning and end stop colors.</p>

            <img src={stopColorStyle} alt="The stop color in xml"/>
            <p>With this information, we can set the stop color in Templative:</p>
            <img src={assigningStopColor} alt="Assigning the end color"/>

            <HeadsUp message="Modifying drop shadows in Templative works similarly to gradients, can you figure it out?"/>
        </React.Fragment>
    }
}