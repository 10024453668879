
import React from "react";
import "./CompanyInfo.css"

export default class PrivacyLanding extends React.Component {
    render() {
        return (
            <div className="row">

                <div className="content col-12 col-sm-8">
                <h1>Privacy Policy</h1>
                <p><strong>Effective Date:</strong> 7/22/2024</p>
                <p><strong>Go Next Games LLC ("we," "our," "us")</strong> is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our product, Templative. By accessing or using our services, you agree to the terms of this Privacy Policy.</p>

                <h2>Information We Collect</h2>
                <h3>1. Personal Information:</h3>
                <ul>
                    <li><strong>Email Address:</strong> Collected during account creation and transactions for account management, support, and marketing communications.</li>
                    <li><strong>Payment Information:</strong> Collected during transactions to process payments.</li>
                </ul>
                <h3>2. Usage Information:</h3>
                <p>Collected when you visit our website, including IP addresses, browser type, and usage data.</p>

                <h2>How We Collect Information</h2>
                <ul>
                    <li>Through account creation forms on our website.</li>
                    <li>During the purchase process.</li>
                    <li>Automatically when you visit our website (e.g., through cookies and tracking technologies).</li>
                </ul>

                <h2>How We Use Your Information</h2>
                <p>We use the information we collect for various purposes, including:</p>
                <ul>
                    <li>To process transactions and manage your account.</li>
                    <li>To provide customer support.</li>
                    <li>To send you updates, marketing communications, and promotional offers.</li>
                    <li>To analyze usage patterns and improve our website and services.</li>
                </ul>

                <h2>Storage and Protection of Information</h2>
                <p>We store your information on a private AWS server. We implement various security measures, including encryption and access controls, to protect your information.</p>

                <h2>Sharing Your Information</h2>
                <p>We do not sell your personal information. However, we may share usage data with third parties for analysis and improvement purposes:</p>
                <ul>
                    <li><strong>Google Analytics:</strong> For website usage analysis.</li>
                    <li><strong>Hotjar:</strong> For website behavior analysis.</li>
                </ul>

                <h2>Use of Cookies and Tracking Technologies</h2>
                <p>We use cookies to track the last used email for login purposes. Cookies help us provide a better user experience and track usage patterns.</p>

                <h2>User Rights</h2>
                <p>You have the right to access, update, or delete your information. To request changes to your information, please email us at <a href="mailto:oliver@gonextgames.com">oliver@gonextgames.com</a>.</p>

                <h2>Data Retention</h2>
                <p>We retain your information for as long as your account is active or as needed to provide you with our services. Currently, we do not delete user information at any interval.</p>

                <h2>Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time. We will notify you of any changes by sending an email to the address associated with your account.</p>

                <h2>Contact Us</h2>
                <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                <p><strong>Go Next Games LLC</strong><br/>
                Email: <a href="mailto:oliver@gonextgames.com">oliver@gonextgames.com</a></p>
                </div>
            </div>
        );
    }
}