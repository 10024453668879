


import React from "react";
import "../LoginForms.css"
import axios from "axios";
import {getCookie} from "../../../CookieManagement";
import { checkIfTokenIsValid } from "../../../TemplativeClient";

const strongPasswordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/

export default class UpdatePassword extends React.Component {
    updateCurrentPassword = (password) => {
        this.setState({currentPassword: password, status: "",})
    }
    updateNewPassword = (password) => {
        this.setState({newPassword: password, status: "",})
    }
    state = {
        currentPassword: "",
        newPassword: "",
        status: "",
    }
    attemptPasswordUpdate = async () => {
        var token = getCookie("TEMPLATIVE_TOKEN")
        var email = getCookie("TEMPLATIVE_EMAIL")
        if (email === null || token === null) {
            window.location.href = `${window.location.origin}/login`  
        }
        try {
            const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '::1';
            const baseurl = isLocalhost ? "http://127.0.0.1:5000" : "https://www.templative.net"
            var response = await axios.post(`${baseurl}/update-password`, { 
                email: email, 
                currentPassword: this.state.currentPassword, 
                newPassword: this.state.newPassword, 
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            switch (response.status) {
                case 200:
                    window.location.href = `${window.location.origin}/`      
                    break;
                case 400:
                    this.setState({status: response.data.message})
                    break;
                case 401:
                    window.location.href = `${window.location.origin}/login`  
                    break;
                case 403:
                    window.location.href = `${window.location.origin}/login`  
                    break;
                default:
                    this.setState({status: "There was an issue with updating your password. Please try again later."})
                    break;
            }
        }
        catch(error) {
            if (error.response) {
                console.log(`Error status code: ${error.response.status}`);
                console.log(`Error details: ${error.response.data}`);
            } else if (error.request) {
                console.log("No response was received from the server.");
            } else {
                console.log('Error', error.message);
            }
        }
    }
    
    componentDidMount = async() => {
        var token = getCookie("TEMPLATIVE_TOKEN")
        var email = getCookie("TEMPLATIVE_EMAIL")
        const hasAuth = !(email === null || token === null || !await checkIfTokenIsValid(email, token))
        if (hasAuth) {
            return
        }
        window.location.href = `${window.location.origin}/login`  
    }
    render() {
        var isLoginValid = strongPasswordPattern.test(this.state.currentPassword) && strongPasswordPattern.test(this.state.newPassword)
        return (
            <div className="account-form">
                <h1>Update Password</h1>
                <div className="input-group input-group-sm" data-bs-theme="dark">
                    <span className="input-group-text">Current Password</span>
                    <input type="password" className="form-control" placehcurrenter=""
                        value={this.state.currentPassword} 
                        onChange={(e)=> this.updateCurrentPassword(e.target.value)} 
                    />
                </div>
                <div className="input-group input-group-sm" data-bs-theme="dark">
                    <span className="input-group-text">New Password</span>
                    <input type="password" className="form-control" placehcurrenter=""
                        value={this.props.newPassword} 
                        onChange={(e)=> this.updateNewPassword(e.target.value)} 
                    />
                </div>
                <p className="login-status">
                    {this.state.status}
                </p>
                <button 
                    className="btn btn-outline-warning" 
                    disabled={!isLoginValid} 
                    onClick={this.attemptPasswordUpdate}
                >
                    Update Password
                </button>
            </div>
        );
    }
}