
import React from "react";
import "./AnnoyanceRow.css"
import "./Rows.css"
import feedbackImage from "./feedback.png"

const painPoints = [
    <><span className="annoyance">Redoing</span> all of your art constantly.</>,
    <>Uploading to the GameCrafter <span className="annoyance">takes forever</span>.</>,
    <><span className="annoyance">Messing up tiling</span> art for Tabletop Playground.</>,
    <><span className="annoyance">Manually assembling</span> a printout for your game.</>
]

export default class AnnoyanceRow extends React.Component {
    render() {
        return (
            <div className="row justify-content-center annoyance-row">
                <div className="row">
                    <div className="col-12 col-md">
                        <h1 className="honest">Let's be honest.<br/>You're <span className="tired">tired of this:</span></h1>
                    </div>
                    <div className="col-12 col-md">
                        {painPoints.map((painPoint) => <p className="pain-point" key={Math.random()}>{painPoint}</p>)}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p className="butWait">There is <span className="aBetterWay">a better way</span>!</p>
                        <img className="" src={feedbackImage} alt="Discord converstation of Templative being useful"/>
                    </div>
                </div>
            </div>
        );
    }
}
