
import React from "react";
import "./AuthorizationLanding.css"
import eye from "../Main/undulatingEye.gif"
import {setCookie, getCookie} from "../../CookieManagement";

export default class LoginLanding extends React.Component {
    decodeBase64(encodedData) {
        return decodeURIComponent(escape(atob(encodedData)));
    }
    grabTokenAndEmail() {
        const queryParams = new URLSearchParams(window.location.search);
        const data = queryParams.get('data');
        if (!data) {
            return
        }
        const [encodedToken, encodedEmail] = data.split('.');
        const token = this.decodeBase64(encodedToken);
        const email = this.decodeBase64(encodedEmail);
        return { token, email }
    }
    componentDidMount = async () => {
        var { token, email } = this.grabTokenAndEmail()
        const cookieDurationDays = 7
        setCookie("TEMPLATIVE_TOKEN", token, cookieDurationDays)
        setCookie("TEMPLATIVE_EMAIL", email, cookieDurationDays)
    }
    goToTemplative = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const data = queryParams.get('data');
        const customUrl = `templative://authorize?data=${data}`;
        var {token, email} = this.grabTokenAndEmail()
        window.location.href = customUrl;
    }
    render() {
        const queryParams = new URLSearchParams(window.location.search);
        const data = queryParams.get('data');
        const error = queryParams.get('error');
        return (
            <div className="row">
                <div className="col">
                    <img src={eye} className="" alt="The Templative Logo"/>
                    {
                        (data !== null && error === null) ?
                        <React.Fragment>
                            <h1>Open Templative</h1>
                            <p>We use this page to redirect logins after signing in.</p>
                            <button onClick={this.goToTemplative}className="btn btn-secondary open-templative-button">Return to Templative</button>
                        </React.Fragment> 
                        :
                        <React.Fragment>
                            <h1>Issue Logging In</h1>
                            <p>{error}</p>
                        </React.Fragment>
                    }
                    
                </div>
            </div>
        );
    }
}