import CreatingyourFirstProject from './docs/./0 Getting Started/Creating your First Project.js';
import FAQ from './docs/./0 Getting Started/FAQ.js';
import InstallingtheTools from './docs/./0 Getting Started/Installing the Tools.js';
import UnderstandingyourTools from './docs/./0 Getting Started/Understanding your Tools.js';
import WhatisTemplative from './docs/./0 Getting Started/What is Templative.js';
import CollaborationandVersionControl from './docs/./1 Making your Game/Collaboration and Version Control.js';
import CreatingComponents from './docs/./1 Making your Game/Creating Components.js';
import Art from './docs/./1 Making your Game/Editing your Components/Art.js';
import ArtdataTricks from './docs/./1 Making your Game/Editing your Components/Artdata Tricks.js';
import Artdata from './docs/./1 Making your Game/Editing your Components/Artdata.js';
import ComponentCompose from './docs/./1 Making your Game/Editing your Components/Component Compose.js';
import Gamedata from './docs/./1 Making your Game/Editing your Components/Gamedata.js';
import RenderingyourGame from './docs/./1 Making your Game/Rendering your Game.js';
import Rules from './docs/./1 Making your Game/Rules.js';
import CreatingaPrintandPlay from './docs/./2 Exporting/Creating a Print and Play.js';
import ExportingtoTabletopPlayground from './docs/./2 Exporting/Exporting to Tabletop Playground.js';
import ExportingtoTabletopSimulator from './docs/./2 Exporting/Exporting to Tabletop Simulator.js';
import UploadingtotheGameCrafter from './docs/./2 Exporting/Uploading to the Game Crafter.js';
import AttendingaConvention from './docs/./3 Sharing your Game/Attending a Convention.js';
import FindingaPublisher from './docs/./3 Sharing your Game/Finding a Publisher.js';

export const articles = {
  "0 Getting Started": {
    "Creating your First Project": (props) => <CreatingyourFirstProject {...props} />,
    "FAQ": (props) => <FAQ {...props} />,
    "Installing the Tools": (props) => <InstallingtheTools {...props} />,
    "Understanding your Tools": (props) => <UnderstandingyourTools {...props} />,
    "What is Templative": (props) => <WhatisTemplative {...props} />
  },
  "1 Making your Game": {
    "Collaboration and Version Control": (props) => <CollaborationandVersionControl {...props} />,
    "Creating Components": (props) => <CreatingComponents {...props} />,
    "Editing your Components": {
      "Art": (props) => <Art {...props} />,
      "Artdata Tricks": (props) => <ArtdataTricks {...props} />,
      "Artdata": (props) => <Artdata {...props} />,
      "Component Compose": (props) => <ComponentCompose {...props} />,
      "Gamedata": (props) => <Gamedata {...props} />
    },
    "Rendering your Game": (props) => <RenderingyourGame {...props} />,
    "Rules": (props) => <Rules {...props} />
  },
  "2 Exporting": {
    "Creating a Print and Play": (props) => <CreatingaPrintandPlay {...props} />,
    "Exporting to Tabletop Playground": (props) => <ExportingtoTabletopPlayground {...props} />,
    "Exporting to Tabletop Simulator": (props) => <ExportingtoTabletopSimulator {...props} />,
    "Uploading to the Game Crafter": (props) => <UploadingtotheGameCrafter {...props} />
  },
  "3 Sharing your Game": {
    "Attending a Convention": (props) => <AttendingaConvention {...props} />,
    "Finding a Publisher": (props) => <FindingaPublisher {...props} />
  }
};