
import React from "react";

export default class Documentation extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Documentation</h1>
            <p>Welcome to Templative! </p>
        </React.Fragment>
    }
}