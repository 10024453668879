
import React from "react";
import UpdatePassword from "./LoginForm/UpdatePassword";
// import "./CompanyInfo.css"

export default class UpdatePasswordLanding extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="content col-12 col-sm-8">
                    <UpdatePassword/>
                </div>
            </div>
        );
    }
}