
import React from "react";
import TemplativePurchaseSuggestion from "../../TemplativePurchaseSuggestion";
import tabletopSimulatorSaves from "./tabletopSimulatorSaves.png"
import uploadingToWorkshop from "./uploadingToWorkshop.png"
import workshopUploadControls from "./workshopUploadControls.png"
import tabletopSimulatorScreen from "./tabletopSimulatorScreen.png"
import inTabletopSim from "./inTabletopSim.png"

export default class TabletopSimulator extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Exporting to Tabletop Simulator</h1>
            <p>Export to Tabletop Simulator to playtest your game with your friends virtually.</p>
            <TemplativePurchaseSuggestion isLoggedIn={this.props.isLoggedIn} doesOwnTemplative={this.props.doesOwnTemplative}/>
            <p>Tabletop Simulator is one of the most common ways to playtest your game. We create custom content by creating a save that we upload to the Steam Workshop. To do this we:</p>
            
                <p>Go to the Tabletop Simulator Screen.</p>
                <p>Put in your Tabletop Simulator saves documents folder, usually `...\Documents\My Games\Tabletop Simulator`</p>
                <p>Select an outputed game.</p>
                <p>Press `Create Simulator Save`. Wait a couple seconds, this operation can take several seconds.</p>
                <img src={tabletopSimulatorScreen} alt="The Tabletop Simulator screen"/>
                <p>Open Tabletop Simulator. Click Create, then Singleplayer. Notice your Templative created saves on the bottom row:</p>
                <img src={tabletopSimulatorSaves} alt="The Tabletop Simulator screen"/>
                <p>Click the game save we just created.</p>
                <img src={inTabletopSim} alt="Our game in Tabletop Simulator"/>
            
            <h2>Uploading our Tabletop Simulator Game to the Steam Workshop</h2>
            <p>Now that we have our save, and have moved things around how we like, uploading it is straightforward.</p>
            
                <p>In the top controls of our Tabletop Sim save, click Modding, then Workshop Upload.</p>
                <img src={uploadingToWorkshop} alt="The Tabletop Simulator screen"/>
                <p>Give it a nice title, description, and thumbnail.</p>
                <img src={workshopUploadControls} alt="The Tabletop Simulator screen"/>
            
            <p>Consider reading <a href="https://blog.tabletopsimulator.com/blog/tabletop-simulator-uploading-to-steam-workshop">Tabletop Simulator's documentation</a>.</p>
        </React.Fragment>
    }
}