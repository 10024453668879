import React from "react";

export default class WhatisTemplative extends React.Component {
    render() {
        return <React.Fragment>
            <h1>FAQ</h1>
            <h2>Does Templative use AI?</h2>
            <p>Templative does not use AI to create art. There is nothing stopping you from providing Templative AI generated art.</p>
            <p>Templative <em>does</em> use AI for matching your game to a publisher in the <a href={`${window.location.origin}/docs/3-sharing-your-game/finding-a-publisher`}>Publisher Search</a> feature. I do not believe that it is inhuman or unethical to match games and publishers based on the publisher's listed criteria using AI.</p>
            <h2>Is Templative a Subscription Service?</h2>
            <p>No.</p>
            <h2>Do I need to know how to Code?</h2>
            <p>No.</p>
            <h2>Can Templative handle XYZ Component?</h2>
            <p>I've tried to make Templative support all of the components that can possibly be created using TheGameCrafter. Truly custom components like the plastic molding that goes within boxes isn't support, nor are custom cardstock cutouts on TheGameCrafter.</p>
            <h2>How Big is the Templative Team?</h2>
            <p>Just me! I'm an Ex Roblox engineer turned solo developer.</p>
        </React.Fragment>
    }
}