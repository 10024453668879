


import React from "react";
import "../LoginForms.css"
import EmailPassword from "./EmailPassword";
import axios from "axios";
import {setCookie, getCookie} from "../../../CookieManagement";
import { checkIfTokenIsValid } from "../../../TemplativeClient";
import ReactGA from 'react-ga4';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const CLIENT_ID = '683633354752-kgdc1pdb36sjiptugpep8ih754eslacc.apps.googleusercontent.com';
const strongPasswordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default class LoginForm extends React.Component {
    updatePassword = (password) => {
        this.setState({password: password, status: ""})
    }
    updateEmail = (email) => {
        this.setState({email: email, status: ""})
    }
    state = {
        email: "",
        password: "",
        status: ""
    }
    
    handleError = async (error) => {
        // var errorMessages = {
        //     400: "The email or password were invalid."
        // }
        // var newStatus = "An error occurred, please try again later."
        // if (errorMessages[error.response.status]) {
        //     newStatus = newStatus
        // }
        if (error.response) {
            console.log(`Error status code: ${error.response.status}`);
            console.log(error);
            console.log(`Error details: ${error.response.data}`);
        } else if (error.request) {
            console.log("No response was received from the server.");
        } else {
            console.log('Error', error.message);
        }
        // this.setState({status: newStatus})
    }
    attemptLogin = async () => {
        try {
            const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '::1';
            const baseurl = isLocalhost ? "http://127.0.0.1:5000" : "https://www.templative.net"
            var response = await axios.post(`${baseurl}/login`, { email: this.state.email, password: this.state.password })
            
            switch (response.status) {
                case 200:
                    const cookieDurationDays = 7
                    setCookie("TEMPLATIVE_TOKEN", response.data.token, cookieDurationDays)
                    setCookie("TEMPLATIVE_EMAIL", response.data.email, cookieDurationDays)
                    ReactGA.event({
                        category: 'User',
                        action: 'Completed Login',
                        label: 'Registration',
                      });
                    window.rdt('track', 'Custom', {customEventName:'Completed Login'});
                    window.location.href = `${window.location.origin}/`      
                    break;
                case 401:
                    this.setState({status: response.data.message})
                    break;
                case 400:
                    this.setState({status: response.data.message})
                    break;
                default:
                    this.setState({status: "There was an issue with registration. Please try again later."})
                    break;
            }    
        }
        catch(error) {
            if (error.response) {
                console.log(`Error status code: ${error.response.status}`);
                console.log(`Error details: ${error.response.data}`);
            } else if (error.request) {
                console.log("No response was received from the server.");
            } else {
                console.log('Error', error.message);
            }
        }
    }
    goToLink = () => {
        window.location.href = `${window.location.origin}/register`        
    }
    componentDidMount = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Started Login',
            label: 'Registration',
          });
        window.rdt('track', 'Custom', {customEventName:'Started Login'});
        var token = getCookie("TEMPLATIVE_TOKEN")
        var email = getCookie("TEMPLATIVE_EMAIL")
        var hasToken = email !== null && token !== null
        const shouldNavigateToHomePage = hasToken && await checkIfTokenIsValid(email, token)
        if (shouldNavigateToHomePage) {
            window.location.href = `${window.location.origin}`  
        }
    }
    responseGoogle = async (googleResponse) => {
        const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '::1';
        const baseurl = isLocalhost ? "http://127.0.0.1:5000" : "https://www.templative.net"
        try {
            var response = await axios.post(`${baseurl}/login/google`, { token: googleResponse.credential })
            switch (response.status) {
                case 200:
                    const cookieDurationDays = 7
                    setCookie("TEMPLATIVE_TOKEN", response.data.token, cookieDurationDays)
                    setCookie("TEMPLATIVE_EMAIL", response.data.email, cookieDurationDays)
                    ReactGA.event({
                        category: 'User',
                        action: 'Completed Login',
                        label: 'Registration',
                      });
                    window.rdt('track', 'Custom', {customEventName:'Completed Login'});
                    window.location.href = `${window.location.origin}/`      
                    break;
                case 401:
                    this.setState({status: response.data.message})
                    break;
                case 400:
                    this.setState({status: response.data.message})
                    break;
                default:
                    this.setState({status: "There was an issue with registration. Please try again later."})
                    break;
            }
        }
        catch(error) {
            await this.handleError(error)
        }
    };
    render() {
        var isLoginValid = emailPattern.test(this.state.email) && strongPasswordPattern.test(this.state.password)
        return (
            <div className="account-form">
                <h1>Login</h1>
                <div className="google-oauth">    
                    <GoogleOAuthProvider clientId={CLIENT_ID}>
                        <GoogleLogin
                            text="Log in using a Google Account"
                            onSuccess={this.responseGoogle}
                            onError={() => {
                            console.log('Login Failed');
                            }}
                        />
                    </GoogleOAuthProvider>
                </div>
                <EmailPassword 
                    email={this.state.email}
                    updateEmailCallback={this.updateEmail}
                    password={this.state.password}
                    updatePasswordCallback={this.updatePassword}
                />
                
                <button 
                    className="btn btn-outline-warning" 
                    disabled={!isLoginValid} 
                    onClick={this.attemptLogin}
                >
                    Login
                </button>
                <p className="login-status">
                    {this.state.status}
                </p>
                <p className="need-templative-account">
                    Need a Templative account? <span className="switch-login-type" onClick={this.goToLink}>Register now</span>.
                </p>
            </div>
        );
    }
}