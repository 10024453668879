
import React from "react";
import { attemptTemplativeDownload } from "../TemplativeClient";
import { getOS } from "../Utility";
import YouTube from 'react-youtube';
import "./ThanksLanding.css"
import ReactGA from 'react-ga4';

export default class ThanksLanding extends React.Component {
    state = {
        isDownloading: false,
        sessionId: undefined
    }
    componentDidMount = async () => {
        ReactGA.event({
            category: 'Ecommerce',
            action: 'Templative Purchase Completed',
            label: 'Transaction',
          });
        window.rdt('track', 'Templative Purchase Completed');
    }
    // componentDidMount = () => {
    //     const queryParams = new URLSearchParams(window.location.search);
    //     this.setState({
    //         sessionId: queryParams.get('sessionId')
    //     });
    // }
    goToBonuses = () => {
        window.location.href = `${window.location.origin}/bonus`   
    }
    downloadTemplative = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Templative Downloaded',
            label: 'App Download',
          });
        window.rdt('track', 'Custom', {customEventName:'Templative Downloaded'});
        this.setState({isDownloading: true})
        await attemptTemplativeDownload()
        this.setState({isDownloading: false})
    }
    render() {
        return (
            <div className="row">
                <div className="content col-12 col-sm-8 thanks-content">
                    <h1>Thank you for your purchase!</h1>
                    <p>From now on, tedium is at and end, and you can focus on making and shipping your board games.</p>
                    
                    <p>If you haven't already, download Templative here:</p>
                    <div className="secondary-action-buttons-row">
    
                        <button onClick={this.downloadTemplative} 
                            disabled={getOS() === undefined || this.state.isDownloading}
                            type="button" className="btn btn-primary primary-action-button" >Download Templative</button>
                    </div>
                    <div className="youtube-instructionals">
                        <YouTube videoId="dfrwzN3TY40" opts={{ height: '390', width: '640', playerVars: { autoplay: 0} }} onReady={this._onReady} onPlay={this._onPlay} />
                        <YouTube videoId="L6F7X9YSJCU" opts={{ height: '390', width: '640', playerVars: { autoplay: 0} }} onReady={this._onReady} onPlay={this._onPlay} />
                    </div>

                    {/* <p>Ready for your bonuses? Visit the <span className="go-to-bonuses" onClick={this.goToBonuses}>Bonuses page</span> to get your exclusive access.</p> */}
                    <p>If you have an questions about Templative, you can email me at <a className="mailto-link" href="mailto:oliver@gonextgames.com">oliver@gonextgames.com</a>. There is also a feedback form embedded within the app.</p>
                    <p className="postscript happy-designing">Happy designing,</p>
                    <p className="postscript">Oliver Barnum - Founder of Go Next Games</p>
                </div>
            </div>
        );
    }
}