
import React from "react";
import "./Logo.css"
import splashImage from "./undulatingEye.gif"

export default class Logo extends React.Component {
    render() {
        return (
            <div className="templative-logo">
                <img className="logo-image" src={splashImage} alt="Templative Logo"/>
                <p className="templative-name">Templative</p>
            </div>
        );
    }
}
