import React from "react";
import "./Footer.css"
import { ColorScheme } from "../ColorScheme";

type FooterProps = {
    companyName: string,
    colorScheme:ColorScheme
}

export default class Footer extends React.Component<FooterProps> {
    render() {
        return (
            <div className={"footer col-12 "  + this.props.colorScheme}>
                <p>{`Go Next Games LLC all rights reserved ${new Date().getFullYear().toString()}.`}</p>
            </div>
        );
    }
}