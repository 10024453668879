import React from "react";
import DownloadRow from "./Rows/DownloadRow";
import DemoRow from "./Rows/DemoRow";
import HowItWorksRow from "./Rows/HowItWorksRow";
import YoutubeShortsRow from "./Rows/YoutubeShortsRow";
import PricingRow from "./Rows/PricingRow";
import AnnoyanceRow from "./Rows/AnnoyanceRow";
import IsItRight from "./Rows/IsItRight";

export default class DownloadLanding extends React.Component {
    render() {
        return (
            <React.Fragment>
                <DownloadRow status={this.props.status}/>
                <AnnoyanceRow/>
                <HowItWorksRow/>
                <PricingRow/>
                <DemoRow email={this.props.email}/>
                {/* <IsItRight/> */}
                <YoutubeShortsRow/>
            </React.Fragment>
        );
    }
}