
import React from "react";
import "./PricingRow.css"

const unlocks = [
    "Render your Game Automagically",
    "Templative Starter Project of Your Game",
    "Create Printable PDFs",
    "Export to Tabletop Simulator",
    "Package with Tabletop Playground",
    "One Click Upload to TheGameCrafter",
    "Publisher Map",
    "Publisher Matchmaker Search",
    "Upcoming Convention Map",
    "Marketing and Production Articles",
    "Full Templative Project of Your Game",
    "Marketing Plan Writeup"
]

const tiers = [
    {
        "name": "Hobbyist",
        "cost": "$0",
        "description": "Explore what's Possible",
        "unlocksUpTo": 2,
        "action": "Try Templative"
    },
    {
        "name": "Game Designer",
        "cost": "$100/One Time",
        "description": "The Tools to Succeed",
        "unlocksUpTo": 9,
        "action": "Get Serious"
    },
    // {
    //     "name": "White Glove",
    //     "cost": "$1000/One Time",
    //     "description": "Get the Game Ready",
    //     "unlocksUpTo": 12,
    //     "action": "Make it Real"
    // }
]

export default class PricingRow extends React.Component {    
    render() {
        var tierElements = tiers.map((tier) => { 
            var unlockElements = []
            for (let u = 0; u < unlocks.length; u++) {
                const unlock = unlocks[u];
                if (tier.unlocksUpTo <= u) {
                    break
                }
                unlockElements.push(<p key="unlock">{unlock}</p>)
            }
            return <div key={tier.name} className="col-12 col-md pricing-column">
                <div>
                    <h3 className="tier-name">{tier.name}</h3>
                    <p className="tier-cost">{tier.cost}</p>
                    <p className="tier-desc">{tier.description}</p>
                    {/* <button className="btn btn-secondary tier-action">{tier.action}</button> */}
                    <div className="price-feature-list">
                        {unlockElements}
                    </div>
                </div>
            </div>
        })
        return (
            <div className="row justify-content-center pricing-row">
                <div className="col">
                    <h2 className="pricing-coa">Let's get your Game Out there.</h2>
                    <div className="pricing-container">
                        <div className="row">
                            {tierElements}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
