import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TermsOfUseLanding from "./components/Company/TermsOfUseLanding"
import PrivacyLanding from "./components/Company/PrivacyLanding"
import DownloadLanding from './components/Main/DownloadLanding';
import Landing from './components/Main/Landing/Landing';
import './App.css';
import LoginLanding from "./components/Login/LoginLanding";
import UpdatePasswordLanding from "./components/Login/UpdatePasswordLanding";
import RegisterLanding from "./components/Login/RegisterLanding";
import BootstrapSizeIndicator from "./SizeIndicator";
import ThanksLanding from "./components/ThanksLanding";
import AuthorizationLanding from "./components/Login/AuthorizationLanding"
import { clearCookie,getCookie } from "./CookieManagement";
import { checkIfTokenIsValid, doesUserOwnTemplative } from "./TemplativeClient";
import DocumentationLanding from "./components/Documentation/DocumentationLanding";

export default class App extends React.Component {
    state= {
        isLoggedIn: false,
        doesOwnTemplative: false,
        email: undefined
    }
    
    logout = async(token) => {
        console.log("logout")
        clearCookie('TEMPLATIVE_TOKEN');
        clearCookie('TEMPLATIVE_EMAIL');
        this.setState({isLoggedIn: false, doesUserOwnTemplative: false, email: undefined}, console.log(this.state))
    }
    checkIfOwnsTemplative = async () => {   
        var token = getCookie("TEMPLATIVE_TOKEN")
        var email = getCookie("TEMPLATIVE_EMAIL")
        if (token === null || email === null) {
            return
        }
        var doesOwnTemplative = await doesUserOwnTemplative(email, token)
        console.log("Owns templative", doesOwnTemplative)
        this.setState({doesOwnTemplative: doesOwnTemplative})
    }
    refreshCredentials = async () => {
        var token = getCookie("TEMPLATIVE_TOKEN")
        var email = getCookie("TEMPLATIVE_EMAIL")
        if (token === null || email === null) {
            console.log("No credentials")
            return
        }
        const isTokenValid = await checkIfTokenIsValid(email, token)
        console.log("Is token valid", isTokenValid)
        var assignedEmail = isTokenValid ? email : undefined
        this.setState({isLoggedIn: isTokenValid, email: assignedEmail}, this.checkIfOwnsTemplative)
    }
    componentDidMount = async() => {
        await this.refreshCredentials()
    }
    
    render = () => {
        const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '::1';
        var status = "guest"
        if (this.state.isLoggedIn) {
            status = this.state.doesOwnTemplative ? "purchased" : "not-purchased"
        }
        return <BrowserRouter>
            <div className="App container-fluid">
                {isLocalhost && <BootstrapSizeIndicator/>}
                
                <Landing logoutCallback={this.logout} isLoggedIn={this.state.isLoggedIn} doesOwnTemplative={this.state.doesOwnTemplative} email={this.state.email} mainPageName="Templative" companyName="Templative" supportEmail='oliver@gonextgames.com' discordInviteLink='https://discord.gg/RqUVQymRup' topBarLinks={[]}>
                <Routes>
                    <Route key="home" path="/" element={
                        <DownloadLanding status={status} email={this.state.email}/>
                    }/>
                    <Route key="login" path="/login" element={
                        <LoginLanding />
                    }/>
                    <Route key="docs"  path="/docs/*" element={
                        <DocumentationLanding  isLoggedIn={this.state.isLoggedIn} doesOwnTemplative={this.state.doesOwnTemplative} />
                    }/>
                    {/* <Route key="bonus" path="/bonus" element={
                        <BonusLanding/>
                    }/> */}
                    <Route key="thanks" path="/thanks" element={
                        <ThanksLanding isLoggedIn={this.state.isLoggedIn} doesOwnTemplative={this.state.doesOwnTemplative}/>
                    }/>
                    <Route key="authorize" path="/authorize" element={
                        <AuthorizationLanding isLoggedIn={this.state.isLoggedIn} doesOwnTemplative={this.state.doesOwnTemplative}/>
                    }/>
                    <Route key="home" path="/register" element={
                        <RegisterLanding  isLoggedIn={this.state.isLoggedIn} doesOwnTemplative={this.state.doesOwnTemplative}/>
                    }/>
                    <Route key="home" path="/update-password" element={
                        <UpdatePasswordLanding  isLoggedIn={this.state.isLoggedIn} doesOwnTemplative={this.state.doesOwnTemplative}/>
                    }/>
                    <Route key="privacyLanding" path="/privacy" element={
                        <PrivacyLanding isLoggedIn={this.state.isLoggedIn} doesOwnTemplative={this.state.doesOwnTemplative}/>
                    }/>
                    <Route key="termsOfUseLanding" path="/terms" element={
                        <TermsOfUseLanding isLoggedIn={this.state.isLoggedIn} doesOwnTemplative={this.state.doesOwnTemplative}/>
                    }/>
                </Routes>
            </Landing>
            </div>
        </BrowserRouter>    
    }
}
