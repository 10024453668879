
import React from "react";
import LoginForm from "./LoginForm/LoginForm";
// import "./CompanyInfo.css"

export default class LoginLanding extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="content col-12 col-sm-8">
                    <LoginForm/>
                </div>
            </div>
        );
    }
}