
import React from "react";
import "./ReviewsRow.css"
import YouTube from "react-youtube";
import {getYoutubeShorts} from "../../../TemplativeClient"

export default class YoutubeShortsRow extends React.Component {
    state = {
        videoIds: []
    }
    fetchLatestVideos = async () => {
        try {
            var shortIds = await getYoutubeShorts()
            if (!shortIds || shortIds === undefined) {
                return
            }
            this.setState({videoIds: shortIds})
        }
        catch {
            
        }
    }
    componentDidMount = async () => {
        await this.fetchLatestVideos()
    }
    
    _onReady(event) {
        event.target.pauseVideo();
    }
    render() {
        const shorts = {height: '640',width: '390',browser: "",playerVars: {autoplay: 0,},};
        return (
            <div className="row justify-content-center">
                { this.state.videoIds.length > 0 &&
                    <div className="col-12">
                        <h1>See it in Action!</h1>
                        
                        <div className="youtube-shorts">
                            {this.state.videoIds.map(videoId => (
                                <YouTube key={videoId} videoId={videoId} opts={shorts} onReady={this._onReady} />
                            ))}
                        </div>
                    </div>
                }
            </div>
        );
    }
}
