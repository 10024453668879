
export const getOS = () => {
    var platform = window.navigator.platform
    var macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    var windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      return 'darwin';
    } 
    if (windowsPlatforms.indexOf(platform) !== -1) {
      return 'win32';
    }
  
    return undefined;
  }